import { Search, Tabs, Tab, Tag, Overlay, List, Icon, Cell, NoticeBar, Image, Grid, GridItem, Swipe, SwipeItem } from 'vant'
import Tabbar from '../common/Tabbar.vue'
import Login from '../../module/intercept/Login.vue'
import Share from '../../module/common/Share.vue'
import Loading from '../../module/common/Loading.vue'
import LogVisit from '../../module/common/LogVisit.vue'
import Call from '../../module/common/Call.vue'
import '../../../assets/icon/font-icon.js'
export default {
    components: {
        Tabbar: Tabbar,
        Login: Login,
        Share: Share,
        Loading: Loading,
        LogVisit: LogVisit,
        Call: Call,
        [Search.name]: Search,
        [Tabs.name]: Tabs,
        [Tab.name]: Tab,
        [Tag.name]: Tag,
        [Overlay.name]: Overlay,
        [List.name]: List,
        [Cell.name]: Cell,
        [Icon.name]: Icon,
        [NoticeBar.name]: NoticeBar,
        [Image.name]: Image,
        [Grid.name]: Grid,
        [GridItem.name]: GridItem,
        [Swipe.name]: Swipe,
        [SwipeItem.name]: SwipeItem
    },
    data () {
        return {
            platform: '',
            onlineShow: false,
            loadingShow: false,
            guideShow: true,
            finished: false,
            loginState: 'N',
            activityList: [
                // { code: '1', label: 'RECRUIT_WORKER', imagePath: '../../../assets/images/integrate/1.jpg', name: '名企普通用工', startTime: '2023-09-01', endTime: '2024-09-01', labels: ['临时工', '短期工', '长期工', '正式工'] },
                { code: '2', label: 'RECRUIT_LOCAL', imagePath: '../../../assets/images/integrate/3.jpg', name: '本地灵活用工', startTime: '2023-09-01', endTime: '2024-09-01', labels: ['建筑', '餐饮', '装修', '装卸', '搬运'] }
            ]
        }
    },
    mounted () {
        document.title = '人生有难题就找人生帮'
        this.$refs.login.validate()
    },
    methods: {
        init () {
            this.platform = window.sessionStorage.getItem(this.SESSION_PLATFORM)
            this.$refs.logVisit.createLog()
            var token = window.sessionStorage.getItem(this.SESSION_TOKEN)
            if (token !== undefined && token !== '' && token !== null) {
                this.initShare()
            }
        },
        work () {
            window.sessionStorage.setItem(window.const.global.WORK_HOME_INDEX_BACK, window.location.href)
            this.$router.push({ path: '/mde/recruit/home' })
        },
        academic () {
            window.sessionStorage.setItem(window.const.global.ACADEMIC_HOME_INDEX_BACK, window.location.href)
            this.$router.push({ path: '/mde/academic/home' })
        },
        cert () {
            window.sessionStorage.setItem(window.const.global.CERT_HOME_INDEX_BACK, window.location.href)
            this.$router.push({ path: '/mde/cert/home' })
        },
        degree () {
            window.sessionStorage.setItem(window.const.global.DEGREE_HOME_INDEX_BACK, window.location.href)
            this.$router.push({ path: '/mde/degree/home' })
        },
        educate () {
            window.sessionStorage.setItem(window.const.global.EDUCATE_HOME_INDEX_BACK, window.location.href)
            this.$router.push({ path: '/mde/educate/home' })
        },
        visa () {
            window.sessionStorage.setItem(window.const.global.VISA_HOME_INDEX_BACK, window.location.href)
            this.$router.push({ path: '/mde/visa/home' })
        },
        study () {
            window.sessionStorage.setItem(window.const.global.STUDY_HOME_INDEX_BACK, window.location.href)
            this.$router.push({ path: '/mde/study/home' })
        },
        labor () {
            window.sessionStorage.setItem(window.const.global.LABOR_HOME_INDEX_BACK, window.location.href)
            this.$router.push({ path: '/mde/labor/home' })
        },
        hire () {
            window.sessionStorage.setItem(window.const.global.HIRE_HOME_INDEX_BACK, window.location.href)
            this.$router.push({ path: '/mde/hire/home' })
        },
        initShare () {
            this.$refs.share.mainDefault()
        },
        focusWpp () {
            this.$router.push({ path: '/mde/focus' })
        },
        activity (label) {
            if (label === 'RECRUIT_WORKER') {
                window.sessionStorage.setItem(window.const.global.RECRUIT_ACTIVITY_WORKER_BACK, window.location.href)
                this.$router.push({ path: '/mde/recruit/activityRctWorker', query: { activityCode: '1' } })
            } else if (label === 'RECRUIT_LOCAL') {
                window.sessionStorage.setItem(window.const.global.RECRUIT_ACTIVITY_WORKER_BACK, window.location.href)
                this.$router.push({ path: '/mde/hire/home' })
            }
        },
        search () {
            this.$router.push({ path: '/mde/recruit/search', query: { type: 'RCT' } })
        }
    }
}
