<template>
  <div class="page" ref="page">
    <Login ref="login" :callback="init" mode="GST" />
    <!-- <div class="search">
      <van-search
        v-model="text"
        readonly="false"
        @focus="search"
        placeholder="请输入搜索关键词"
      ></van-search>
    </div> -->
    <van-swipe class="banner" :autoplay="6000" indicator-color="white">
      <van-swipe-item @click="helper()">
        <img
          class="img"
          :src="require('../../../assets/images/integrate/ban.jpg')"
        />
      </van-swipe-item>
    </van-swipe>
    <div class="classify">
      <van-grid>
        <van-grid-item text="工作推荐" key="1" @click="work()">
          <template #icon>
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-recruit"></use>
            </svg>
          </template>
        </van-grid-item>
        <van-grid-item text="普工招聘" key="2" @click="hire()">
          <template #icon>
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-engineer"></use>
            </svg>
          </template>
        </van-grid-item>
        <van-grid-item text="职称考评" key="2" @click="academic()">
          <template #icon>
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-audit"></use>
            </svg>
          </template>
        </van-grid-item>
        <van-grid-item text="证书考培" key="1" @click="cert()">
          <template #icon>
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-tra"></use>
            </svg>
          </template>
        </van-grid-item>
        <van-grid-item text="学历提升" key="1" @click="degree()">
          <template #icon>
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-edu"></use>
            </svg>
          </template>
        </van-grid-item>
        <van-grid-item text="K12入学" key="1" @click="educate()">
          <template #icon>
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-school"></use>
            </svg>
          </template>
        </van-grid-item>
        <van-grid-item text="出国签证" key="1" @click="visa()">
          <template #icon>
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-visa"></use>
            </svg>
          </template>
        </van-grid-item>
        <!-- <van-grid-item text="出国留学" key="1" @click="study()">
          <template #icon>
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-word"></use>
            </svg>
          </template>
        </van-grid-item> -->
        <van-grid-item text="出国务工" key="1" @click="labor()">
          <template #icon>
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-worker"></use>
            </svg>
          </template>
        </van-grid-item>
      </van-grid>
    </div>
    <van-row class="advert">
      <van-col span="24">
        <van-image
          class="img"
          @click="focusWpp()"
          :src="require('../../../assets/images/focus.jpg')"
        ></van-image>
      </van-col>
    </van-row>
    <div class="activity">
      <van-row class="title">
        <van-col span="8" class="text"
          ><van-icon name="notes-o" /> 活动专场</van-col
        >
        <van-col span="16" class="btn color-m"
          ><div @click="allTask()">所有专场 <van-icon name="arrow" /></div
        ></van-col>
      </van-row>
      <div class="act-list">
        <van-row v-for="item in activityList" :key="item.code">
          <van-col @click="activity(item.label)" span="24">
            <van-row class="act">
              <van-col span="24">
                <div class="image">
                  <van-image
                    v-if="item.code === '1'"
                    :src="require('../../../assets/images/integrate/1.jpg')"
                  ></van-image>
                  <van-image
                    v-if="item.code === '2'"
                    :src="require('../../../assets/images/integrate/3.jpg')"
                  ></van-image>
                </div>
                <van-row>
                  <van-col span="24" class="time"
                    >时间：{{ item.startTime }} 到 {{ item.endTime }}</van-col
                  >
                </van-row>
                <van-row>
                  <van-col span="24" class="label"
                    ><van-tag
                      :color="COLOR_M"
                      class="lb"
                      v-for="label in item.labels"
                      :key="label"
                      >{{ label }}</van-tag
                    ></van-col
                  >
                </van-row>
              </van-col>
            </van-row>
          </van-col>
        </van-row>
      </div>
    </div>
    <div class="navigate">
      <van-row class="title">
        <van-col span="8" class="text"
          ><van-icon name="notes-o" /> 业务导航</van-col
        >
        <!-- <van-col span="16" class="btn color-m"
          ><div @click="allTask()">所有专场 <van-icon name="arrow" /></div
        ></van-col> -->
      </van-row>
      <van-tabs
        v-model="active"
        scrollspy
        sticky
        :color="COLOR_M"
        border="true"
      >
        <van-tab title="工作推荐">
          <div class="content" @click="work()">
            <van-row class="title">
              <van-col span="24" class="text"
                ><van-icon name="notes-o" /> 工作推荐</van-col
              >
            </van-row>
            <van-row class="list bbd">
              <van-col span="6" class="group"
                ><van-tag :color="COLOR_M" class="item"
                  >单位类别</van-tag
                ></van-col
              >
              <van-col span="18" class="items">
                <van-tag class="item">国央企</van-tag>
                <van-tag class="item">行政单位</van-tag>
                <van-tag class="item">研究院</van-tag>
                <van-tag class="item">铁路</van-tag>
                <van-tag class="item">医院</van-tag>
                <van-tag class="item">学校</van-tag>
                <van-tag class="item">银行</van-tag>
              </van-col>
            </van-row>
            <van-row class="list bbd">
              <van-col span="6" class="group"
                ><van-tag :color="COLOR_M" class="item"
                  >招聘方式</van-tag
                ></van-col
              >
              <van-col span="18" class="items">
                <van-tag class="item">岗位直招</van-tag>
                <van-tag class="item">公招报名</van-tag>
                <van-tag class="item">定向猎头</van-tag>
              </van-col>
            </van-row>
            <van-row class="list bbd">
              <van-col span="6" class="group"
                ><van-tag :color="COLOR_M" class="item"
                  >岗位类型</van-tag
                ></van-col
              >
              <van-col span="18" class="items">
                <van-tag class="item">技术岗</van-tag>
                <van-tag class="item">行政岗</van-tag>
                <van-tag class="item">后勤岗</van-tag>
                <van-tag class="item">财务岗</van-tag>
              </van-col>
            </van-row>
            <van-row class="list bbd">
              <van-col span="6" class="group"
                ><van-tag :color="COLOR_M" class="item"
                  >热门岗位</van-tag
                ></van-col
              >
              <van-col span="18" class="items">
                <van-tag class="item">教师</van-tag>
                <van-tag class="item">护士</van-tag>
                <van-tag class="item">辅警</van-tag>
                <van-tag class="item">社区网格员</van-tag>
              </van-col>
            </van-row>
            <van-row class="list bbd">
              <van-col span="6" class="group"
                ><van-tag :color="COLOR_M" class="item"
                  >综合收入</van-tag
                ></van-col
              >
              <van-col span="18" class="items">
                <van-tag class="item">3000-5000/月</van-tag>
                <van-tag class="item">5000-8000/月</van-tag>
                <van-tag class="item">8000-15000/月</van-tag>
                <van-tag class="item">15000-30000/月</van-tag>
                <van-tag class="item">30000以上/月</van-tag>
              </van-col>
            </van-row>
            <van-row class="list">
              <van-col span="6" class="group"
                ><van-tag :color="COLOR_M" class="item"
                  >热门单位</van-tag
                ></van-col
              >
              <van-col span="18" class="items">
                <van-tag class="item">陕煤集团</van-tag>
                <van-tag class="item">延长集团</van-tag>
                <van-tag class="item">陕建集团</van-tag>
              </van-col>
            </van-row>
          </div>
        </van-tab>
        <van-tab title="职称考评" @click="academic()">
          <div class="content">
            <van-row class="title">
              <van-col span="24" class="text"
                ><van-icon name="notes-o" /> 职称考评</van-col
              >
            </van-row>
            <van-row class="list bbd">
              <van-col span="6" class="group"
                ><van-tag :color="COLOR_M" class="item"
                  >职称类别</van-tag
                ></van-col
              >
              <van-col span="18" class="items">
                <van-tag class="item">助理工程师</van-tag>
                <van-tag class="item">中级工程师</van-tag>
                <van-tag class="item">高级工程师</van-tag>
              </van-col>
            </van-row>
            <van-row class="list">
              <van-col span="6" class="group"
                ><van-tag :color="COLOR_M" class="item"
                  >行业分类</van-tag
                ></van-col
              >
              <van-col span="18" class="items">
                <van-tag class="item">通信工程</van-tag>
                <van-tag class="item">建筑工程</van-tag>
                <van-tag class="item">冶金工程</van-tag>
                <van-tag class="item">水利水电</van-tag>
                <van-tag class="item">石油化工</van-tag>
                <van-tag class="item">林业工程</van-tag>
                <van-tag class="item">交通运输</van-tag>
                <van-tag class="item">环保工程</van-tag>
                <van-tag class="item">机电工程</van-tag>
                <van-tag class="item">地理测绘</van-tag>
                <van-tag class="item">电力工程</van-tag>
                <van-tag class="item">电子工程</van-tag>
                <van-tag class="item">标准化</van-tag>
                <van-tag class="item">计量工程</van-tag>
                <van-tag class="item">质量工程</van-tag>
              </van-col>
            </van-row>
          </div>
        </van-tab>
        <van-tab title="技能考培" @click="cert()">
          <div class="content">
            <van-row class="title">
              <van-col span="24" class="text"
                ><van-icon name="notes-o" /> 技能考培</van-col
              >
            </van-row>
            <van-row class="list bbd">
              <van-col span="6" class="group"
                ><van-tag :color="COLOR_M" class="item"
                  >专业资格</van-tag
                ></van-col
              >
              <van-col span="18" class="items">
                <van-tag class="item">初级会计</van-tag>
                <van-tag class="item">教师资格</van-tag>
                <van-tag class="item">期货从业资格</van-tag>
                <van-tag class="item">证券从业资格</van-tag>
                <van-tag class="item">导游资格</van-tag>
              </van-col>
            </van-row>
            <van-row class="list">
              <van-col span="6" class="group"
                ><van-tag :color="COLOR_M" class="item"
                  >建筑行业</van-tag
                ></van-col
              >
              <van-col span="18" class="items">
                <van-tag class="item">二级建造师</van-tag>
                <van-tag class="item">一级建造师</van-tag>
                <van-tag class="item">二级造价师</van-tag>
                <van-tag class="item">一级造价师</van-tag>
                <van-tag class="item">安全工程师（CSE）</van-tag>
                <van-tag class="item">消防安全工程师</van-tag>
              </van-col>
            </van-row>
          </div>
        </van-tab>
        <van-tab title="学历提升" @click="degree()">
          <div class="content">
            <van-row class="title">
              <van-col span="24" class="text"
                ><van-icon name="notes-o" /> 学历提升</van-col
              >
            </van-row>
            <van-row class="list bbd">
              <van-col span="6" class="group"
                ><van-tag :color="COLOR_M" class="item"
                  >国内学历</van-tag
                ></van-col
              >
              <van-col span="18" class="items">
                <van-tag class="item">无学历升专科</van-tag>
                <van-tag class="item">高中升专科</van-tag>
                <van-tag class="item">专科升本科</van-tag>
                <van-tag class="item">专本套读</van-tag>
                <van-tag class="item">无学历升本科</van-tag>
                <van-tag class="item">高中升本科</van-tag>
                <van-tag class="item">第二学历</van-tag>
                <van-tag class="item">上班族升本科</van-tag>
              </van-col>
            </van-row>
            <van-row class="list">
              <van-col span="6" class="group"
                ><van-tag :color="COLOR_M" class="item"
                  >海外学历</van-tag
                ></van-col
              >
              <van-col span="18" class="items">
                <van-tag class="item">管理类硕士(不出国)</van-tag>
              </van-col>
            </van-row>
          </div>
        </van-tab>
        <van-tab title="K12教育"
          ><div class="content" @click="educate()">
            <van-row class="title">
              <van-col span="24" class="text"
                ><van-icon name="notes-o" /> K12入学</van-col
              >
            </van-row>
            <van-row class="list bbd">
              <van-col span="6" class="group"
                ><van-tag :color="COLOR_M" class="item"
                  >学校类型</van-tag
                ></van-col
              >
              <van-col span="18" class="items">
                <van-tag class="item">小学</van-tag>
                <van-tag class="item">初中</van-tag>
                <van-tag class="item">高中</van-tag>
              </van-col>
            </van-row>
            <van-row class="list">
              <van-col span="6" class="group"
                ><van-tag :color="COLOR_M" class="item"
                  >业务类型</van-tag
                ></van-col
              >
              <van-col span="18" class="items">
                <van-tag class="item">升学咨询</van-tag>
                <van-tag class="item">转学咨询</van-tag>
                <van-tag class="item">择校咨询</van-tag>
                <van-tag class="item">借读咨询</van-tag>
              </van-col>
            </van-row>
          </div></van-tab
        >
        <van-tab title="出国签证"
          ><div class="content" @click="visa()">
            <van-row class="title">
              <van-col span="24" class="text"
                ><van-icon name="notes-o" /> 出国签证</van-col
              >
            </van-row>
            <van-row class="list bbd">
              <van-col span="6" class="group"
                ><van-tag :color="COLOR_M" class="item"
                  >热门国家</van-tag
                ></van-col
              >
              <van-col span="18" class="items">
                <van-tag class="item">美国</van-tag>
                <van-tag class="item">加拿大</van-tag>
                <van-tag class="item">澳大利亚</van-tag>
                <van-tag class="item">新西兰</van-tag>
                <van-tag class="item">英国</van-tag>
                <van-tag class="item">法国</van-tag>
                <van-tag class="item">德国</van-tag>
                <van-tag class="item">爱尔兰</van-tag>
              </van-col>
            </van-row>
            <van-row class="list">
              <van-col span="6" class="group"
                ><van-tag :color="COLOR_M" class="item"
                  >签证类型</van-tag
                ></van-col
              >
              <van-col span="18" class="items">
                <van-tag class="item">留学签</van-tag>
                <van-tag class="item">旅游签</van-tag>
                <van-tag class="item">工作签</van-tag>
                <van-tag class="item">探亲签</van-tag>
                <van-tag class="item">访友签</van-tag>
                <van-tag class="item">商务签</van-tag>
              </van-col>
            </van-row>
          </div></van-tab
        >
        <van-tab title="出国留学"
          ><div class="content" @click="study()">
            <van-row class="title">
              <van-col span="24" class="text"
                ><van-icon name="notes-o" /> 出国留学</van-col
              >
            </van-row>
            <van-row class="list bbd">
              <van-col span="6" class="group"
                ><van-tag :color="COLOR_M" class="item"
                  >热门国家</van-tag
                ></van-col
              >
              <van-col span="18" class="items">
                <van-tag class="item">美国</van-tag>
                <van-tag class="item">英国</van-tag>
                <van-tag class="item">新加坡</van-tag>
                <van-tag class="item">加拿大</van-tag>
                <van-tag class="item">澳大利亚</van-tag>
                <van-tag class="item">新西兰</van-tag>
                <van-tag class="item">德国</van-tag>
                <van-tag class="item">法国</van-tag>
                <van-tag class="item">荷兰</van-tag>
                <van-tag class="item">日本</van-tag>
                <van-tag class="item">韩国</van-tag>
                <van-tag class="item">俄罗斯</van-tag>
              </van-col>
            </van-row>
            <van-row class="list">
              <van-col span="6" class="group"
                ><van-tag :color="COLOR_M" class="item"
                  >等级类型</van-tag
                ></van-col
              >
              <van-col span="18" class="items">
                <van-tag class="item">小学</van-tag>
                <van-tag class="item">中学</van-tag>
                <van-tag class="item">本科</van-tag>
                <van-tag class="item">硕士</van-tag>
                <van-tag class="item">博士</van-tag>
              </van-col>
            </van-row>
            <van-row class="list">
              <van-col span="6" class="group"
                ><van-tag :color="COLOR_M" class="item"
                  >学习方式</van-tag
                ></van-col
              >
              <van-col span="18" class="items">
                <van-tag class="item">国外(线下)</van-tag>
                <van-tag class="item">国内(线上)</van-tag>
                <van-tag class="item">国内+国外</van-tag>
              </van-col>
            </van-row>
          </div></van-tab
        >
        <van-tab title="国外劳务"
          ><div class="content" @click="labor()">
            <van-row class="title">
              <van-col span="24" class="text"
                ><van-icon name="notes-o" /> 国外劳务</van-col
              >
            </van-row>
            <van-row class="list bbd">
              <van-col span="6" class="group"
                ><van-tag :color="COLOR_M" class="item"
                  >热门推荐</van-tag
                ></van-col
              >
              <van-col span="18" class="items">
                <van-tag class="item">一带一路(央企外派)</van-tag>
                <van-tag class="item">新加坡</van-tag>
                <van-tag class="item">澳大利亚</van-tag>
                <van-tag class="item">新西兰</van-tag>
                <van-tag class="item">日本</van-tag>
                <van-tag class="item">德国</van-tag>
              </van-col>
            </van-row>
            <van-row class="list">
              <van-col span="6" class="group"
                ><van-tag :color="COLOR_M" class="item"
                  >岗位类型</van-tag
                ></van-col
              >
              <van-col span="18" class="items">
                <van-tag class="item">普工</van-tag>
                <van-tag class="item">服务员</van-tag>
                <van-tag class="item">货车司机</van-tag>
                <van-tag class="item">养殖工</van-tag>
                <van-tag class="item">厨师</van-tag>
                <van-tag class="item">按摩师</van-tag>
                <van-tag class="item">木工</van-tag>
                <van-tag class="item">钣金工</van-tag>
                <van-tag class="item">建筑工</van-tag>
              </van-col>
            </van-row>
          </div></van-tab
        >
      </van-tabs>
    </div>

    <!-- <van-overlay :show="guideShow">
      <div class="wrapper" @click="guideShow = false">
        <div class="guide">
          <van-image
            class="img"
            :src="require('../../../assets/images/helper.jpg')"
          ></van-image>
          <van-button
            plain
            :color="COLOR_M"
            size="mini"
            class="btn"
            icon="info-o"
            @click="helper"
            >立即体验</van-button
          >
        </div>
      </div>
    </van-overlay> -->
    <Call ref="call" win-show="false" entry-show="true" />
    <Loading :show="loadingShow" />
    <Share ref="share" />
    <LogVisit ref="logVisit" module-type="IGE" module-label="IGE_HOME_INDEX" />
    <Tabbar active="home" />
    <!-- <Tabbar mode="enroll" active="home" /> -->
  </div>
</template>
<script>
import Index from './Index.js'
export default {
  ...Index
}
</script>
<style lang="less" scoped>
@import './Index.less';
</style>
